<template>
  <div class="row justify-center bg-grey-3">
    <q-card flat bordered style="width:300px">
      <q-card-section class="q-gutter-md q-pb-xl">
        <q-select
          outlined
          v-model="selPeriod"
          label="Periode"
          :options="period"
          @input="getSantriList()"
        />
        <q-select
          outlined
          :disable="selPeriod == null"
          v-model="selKegiatan"
          label="Kegiatan"
          :options="kegiatan"
          @input="getSantriList()"
        />
      </q-card-section>
      <q-separator></q-separator>
      <q-card-section>
        <q-input outlined label="Cari Siswa" v-model="searchTerm">
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-markup-table
        flat
        bordered
        wrap-cells
        dense
        separator="horizontal"
        class="stickyTable bg-grey-4"
        style="height:calc(100vh - 318px);"
      >
        <thead class="bg-indigo-5 text-white text-left">
          <tr class="text-left">
            <th>Nama</th>
            <th class="text-center">
              <strong>H</strong>
              <div class="text-caption text-grey-5">{{ summary.hadir }}</div>
            </th>
            <th class="text-center">
              <strong>M</strong>
              <div class="text-caption text-grey-5">{{ summary.masbuk }}</div>
            </th>
            <th class="text-center">
              <strong>A</strong>
              <div class="text-caption text-grey-5">{{ summary.alfa }}</div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-if="selPeriod == null">
            <td class="text-center" colspan="4">
              Silahkan Pilih Periode Dahulu
            </td>
          </tr>
          <tr v-else-if="selKegiatan == null">
            <td class="text-center" colspan="4">
              Silahkan Pilih Kegiatan Dahulu
            </td>
          </tr>
          <tr v-else-if="siswaTampil.length == 0">
            <td class="text-center" colspan="4">Belum Ada Riwayat Absensi</td>
          </tr>
          <tr v-for="(val, i) in filSiswa" :key="i">
            <td>{{ val.nama }}</td>
            <td class="text-center">{{ val.hadir }}</td>
            <td class="text-center">{{ val.masbuk }}</td>
            <td class="text-center">{{ val.alfa }}</td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      summary: {},
      selKegiatan: { label: "SHUBUH", value: 1 },
      kegiatan: [
        { label: "SHUBUH", value: 1 },
        { label: "DZUHUR", value: 2 },
        { label: "ASHAR", value: 3 },
        { label: "MAGHRIB", value: 4 },
        { label: "ISYA", value: 5 },
      ],
      selPeriod: null,
      period: [],
      siswa: [],
      summary: {
        hadir: 0,
        alfa: 0,
        masbuk: 0,
      },
      siswaTampil: [],
      searchTerm: "",
    };
  },
  mounted() {
    this.getPeriodeList();
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswaTampil;
      else {
        return this.siswaTampil.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          return cNama;
        });
      }
    },
  },
  methods: {
    getPeriodeList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      this.$http.get("/hasil/evaluasi_kehadiran/getperiod/").then((resp) => {
        for (let item of resp.data) {
          item.label = `${item.nama} - Semester ${item.semester}`;
        }
        this.period = resp.data;
      });
      this.$q.loading.hide();
    },
    async getSantriList() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/hasil/rekap_absen/getsiswa/${localStorage.getItem(
          "id_tahun_ajaran"
        )}/${this.selKegiatan.label}`
      );

      this.siswa = resp.data;

      var a = null;

      a = this.siswa.filter(function(x) {
        return x.is_hadir == 1;
      });
      this.summary.hadir = a.length;

      a = this.siswa.filter(function(x) {
        return x.is_masbuq == 1;
      });
      this.summary.masbuk = a.length;

      a = this.siswa.filter(function(x) {
        return x.is_hadir == 0 && x.is_haid == 0 && x.is_masbuq == 0;
      });
      this.summary.alfa = a.length;

      this.$q.loading.hide();
      this.getSantriTampil();
    },
    async getSantriTampil() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let temp = [];
      let listid = [];

      let resp = await this.$http.get(
        `/hasil/rekap_absen/getpanjang/${localStorage.getItem(
          "id_tahun_ajaran"
        )}/${this.selKegiatan.label}`
      );

      listid = resp.data;

      for (let el of listid) {
        let person = this.siswa.filter(function(x) {
          return x.id_siswa == el.id_siswa;
        });
        if (person.length > 0) {
          let carier = {};
          carier.nama = person[0].nama;

          var a = null;

          a = person.filter(function(x) {
            return x.is_hadir == 1;
          });
          carier.hadir = a.length;

          a = person.filter(function(x) {
            return x.is_masbuq == 1;
          });
          carier.masbuk = a.length;

          a = person.filter(function(x) {
            return x.is_hadir == 0 && x.is_haid == 0 && x.is_masbuq == 0;
          });
          carier.alfa = a.length;

          temp.push(carier);
        }
      }
      this.siswaTampil = temp;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/quasar.sass";
</style>
